* {
  margin: 0;
  padding: 0;
}

.container {
  background-color: rgb(48, 42, 42);
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 1em;
  font-size: large;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 50px; */
}

.link {
  color: white;
  text-decoration: none;
  font-weight: 600;
}

.copyLink {
  font-size: large;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

p {
  width: 400px;
  margin: 10px 0;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
